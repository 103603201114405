.root {
  font-family: var(--font-unbounded), sans-serif !important;
  font-size: clamp(
    20px,
    calc(20px + (38 - 20) * ((100vw - 375px) / (1440 - 375))),
    38px
  ) !important;
  font-weight: bold;
  line-height: normal;
  background: linear-gradient(
    90deg,
    #f8f4ff 3%,
    #ff00f5 26%,
    #a532ff 41%,
    #0066ff 62%,
    #f8f4ff 91%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;

  //@media (min-width: 375px){
  //  font-size: 32px;
  //  line-height: 40px;
  //}
  //
  //@media (min-width: 1440px){
  //  font-size: 38px;
  //  line-height: 48px;
  //}
}
