.root {
  width: 100%;
  height: 800px;
  overflow: hidden;
  padding-top: 45px;
}

.container {
  height: 800px;
  overflow: hidden;
  position: relative;
}

.header_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.subtitle_p {
  font-size: 16px;
  font-weight: 300;
  margin-top: 24px;
  position: relative;
  z-index: 10;
}

.nav_container {
  display: flex;
  gap: 22px;
}

.title_container {
  margin-top: 80px;

  @media (max-width: 820px) {
    margin-top: 30px;
  }
}

.title {
  position: relative;
  z-index: 10;
  font-size: 64px;
  line-height: 68px;
  font-weight: 400;
  max-width: 600px;

  @media (max-width: 556px) {
    font-size: 30px;
    line-height: 45px;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-top: 24px;
  position: relative;
  z-index: 10;
  max-width: 700px;

  @media (max-width: 820px) {
    margin-top: 15px;
    font-size: 14px;
  }
}

.sub_subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-top: 44px;
  position: relative;
  z-index: 10;
  max-width: 700px;

  @media (max-width: 820px) {
    margin-top: 15px;
    font-size: 14px;
  }
}

.login_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;

  @media (max-width: 820px) {
    margin-top: 120px;
  }
}
