.root {
  width: 24px;
  height: 26px;
  position: relative;
  z-index: 10;
  cursor: pointer;
}

.menu {
  display: none;
  flex-direction: column;
  width: 200px;
  position: absolute;
  z-index: 12;
  top: 26px;
  transition: all 0.5 ease-in-out;
  padding-top: 5px;
}

.label_container {
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--white);
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  transition: all 0.5 ease-in-out;

  &:hover {
    transition: all 0.5 ease-in-out;
    background-color: var(--white);

    .label {
      transition: all 0.5 ease-in-out;
      color: var(--black);
    }
  }
}

.label {
  font-size: 12;
  font-weight: 500;
  color: var(--white);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 8px;
}

.container {
  position: relative;
  // width: 200px;
  display: flex;
  justify-content: flex-end;

  &:hover {
    .menu {
      display: flex;
    }
  }

  @media (max-width: 820px) {
    &:hover {
      .menu {
        display: none;
      }
    }
  }
}
