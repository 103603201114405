.FunctionalityOfSystem {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-bottom: 32px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  color: var(--white);
  font-family: var(--font-unbounded), sans-serif !important;
  font-size: clamp(
    16px,
    calc(16px + (24 - 16) * ((100vw - 375px) / (1440 - 375))),
    24px
  );
  line-height: normal;
  text-transform: uppercase;
  word-wrap: break-word;
}

.ul {
  list-style: none;
}

.li,
.li2 {
  display: flex;
  color: var(--white);
  font-family: var(--font-montserrat), sans-serif !important;
  font-size: clamp(12px, 12px + 4 * (100vw - 375px) / 1065, 16px);
  line-height: 26px !important;
  letter-spacing: 0.24px;
  word-wrap: break-word;
}

.li::before {
  content: '•';
  color: var(--white);
  font-size: 16px;
  padding-right: 8px;
}

.li2::before {
  content: '•';
  color: var(--white);
  font-size: 16px;
  padding-left: 20px;
  padding-right: 8px;
}

@media (min-width: 1440px) {
  .listContainer {
    flex-direction: row;
  }
}
