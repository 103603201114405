.description {
  padding-top: 24px;
  padding-bottom: 16px;
  font-family: var(--font-montserrat), sans-serif !important;
  font-size: clamp(
    14px,
    calc(14px + (16 - 14) * ((100vw - 820px) / (1440 - 820))),
    16px
  );
  font-weight: bold;
  line-height: 26px !important;
  letter-spacing: 0.28px;
  word-wrap: break-word;
  color: var(--white);
}

.gradient {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url('../../../../public/assets/icons/LandingIcons/IntegrationsIcons/LogoBg/LogoBg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  gap: 36px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.logoContainer {
  position: relative;
  z-index: 1000;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 24px 10px;

  @media (min-width: 820px) {
    grid-template-columns: repeat(9, 1fr);
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;

  @media (min-width: 820px) {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 1300px) {
    width: 70px;
    height: 70px;
  }
}

.additional {
  padding-top: 16px;
  color: var(--white);
  font-family: var(--font-montserrat), sans-serif !important;
  font-size: clamp(
    14px,
    calc(14px + (16 - 14) * ((100vw - 820px) / (1440 - 820))),
    16px
  );
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.24px;
  word-wrap: break-word;
}
