.button {
  position: relative;
  z-index: 11;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.button_container {
  position: absolute;
  min-width: 128px;
  min-height: 128px;
  border: 4px solid var(--white);
  border-radius: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.container {
  position: relative;
  width: 128px;
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
  border: none;
  background: transparent;
  color: var(--white);
}
