.InfoItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: transform 0.3s ease-in-out;
}

.itemTitle {
  color: var(--white);
  font-family: var(--font-unbounded), sans-serif !important;
  font-size: clamp(
    16px,
    calc(16px + (24 - 16) * ((100vw - 375px) / (1440 - 375))),
    24px
  );
  line-height: normal;
  text-transform: uppercase;
  word-wrap: break-word;
}

.itemDescription {
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  color: var(--white);
  font-family: var(--font-montserrat), sans-serif !important;
  font-size: clamp(
    12px,
    calc(12px + (16 - 12) * ((100vw - 375px) / (1440 - 375))),
    16px
  );
  line-height: 24px !important;
  letter-spacing: 0.24px;
  word-wrap: break-word;
}

@media (min-width: 1440px) {
  .InfoItem:hover {
    .imageContainer > svg > path {
      stroke: url(#customGradient);
    }

    .imageContainer {
      transform: translate(0px, -20px);
    }

    .itemTitle {
      background: var(--gradient3);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }

    .itemDescription {
      transform: translate(0px, -20px);
    }
  }

  .imageContainer {
    gap: 14px;
  }

  .imageContainer > svg {
    width: 92px;
    height: 92px;
  }

  .itemTitle {
    font-weight: bold;
  }
}
