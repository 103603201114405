.root {
  margin-bottom: 30px;
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
  margin-top: 32px;
  margin-bottom: 36px;
}

.cards {
  display: flex;
  gap: 30px;

  @media (max-width: 820px) {
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: 556px) {
    gap: 16px;
  }
}
