.subtitle {
  font-size: 15px;
  font-weight: 200;
  color: var(--white);
}

.cards {
  display: flex;
  flex-direction: row;
}

.root {
  padding: 36px 50px;
  position: relative;
  overflow: hidden;
  background: url(/assets/images/gradient6.png);
  height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 2500px;
}
.title {
  color: var(--white);
  font-family: var(--font-unbounded), sans-serif !important;
  font-size: clamp(
    16px,
    calc(16px + (24 - 16) * ((100vw - 375px) / (1440 - 375))),
    24px
  );
  line-height: normal;
  text-transform: uppercase;
  word-wrap: break-word;
  margin: 10px 0px 10px 0px;
}

.containerTitle {
  color: var(--white);
  font-family: var(--font-unbounded), sans-serif !important;
  font-size: clamp(
    16px,
    calc(16px + (24 - 16) * ((100vw - 375px) / (1440 - 375))),
    24px
  );
  line-height: normal;
  word-wrap: break-word;
  margin: 10px 0px 10px 0px;
}

.containerBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
