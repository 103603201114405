.Footer {
  display: flex;
  flex-direction: column;
}

.footerGradient {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-image: url('../../../../public/assets/icons/LandingIcons/FooterIcons/FooterBg/FooterBg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.logoStyle {
  width: 160px;
  display: flex;
  @media (max-width: 820px) {
    width: 115px;
  }
  @media (max-width: 768px) {
    width: 115px;
  }

  @media (max-width: 556px) {
    width: 75px;
  }
}
.footerInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
.footerInfo {
  color: white;
  font-size: 12px;
  font-family: var(--font-unbounded), sans-serif !important;
  @media (max-width: 556px) {
    font-size: 9px;
  }
}

@media (min-width: 820px) {
  .footerInfoContainer {
    gap: 10px;
  }
}

.footerHead {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000;
}
