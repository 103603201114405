.interactiveInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.labels {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
}

.desktopCenter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
}

.container {
  display: flex;
  flex-direction: column;
}

.itemDescription {
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  color: var(--white);
  font-family: var(--font-montserrat), sans-serif !important;
  font-size: clamp(
    12px,
    calc(12px + (16 - 12) * ((100vw - 375px) / (1440 - 375))),
    16px
  );
  line-height: 24px !important;
  letter-spacing: 0.24px;
  word-wrap: break-word;
}

@media (min-width: 1100px) {
  .container {
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 36px;
    margin-top: 20px;
  }

  .desktopCenter {
    justify-content: center;
    gap: 20px;
  }

  .labels {
    width: 100%;
    max-width: 760px;
  }
}
