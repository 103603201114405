.root {
  padding: 36px 50px;
  position: relative;
  overflow: hidden;
  background: url('/assets/images/gradient1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 820px) {
    padding: 32px 27px;
  }
}

.root2 {
  padding: 36px 50px;
  position: relative;
  overflow: hidden;
  background: url('/assets/images/gradient2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 820px) {
    padding: 32px 27px;
  }

  @media (max-width: 556px) {
    margin-top: 16px;
  }
}

.title {
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  margin-top: 84px;
  color: var(--white);
  position: relative;
  z-index: 10;

  @media (max-width: 820px) {
    margin-top: 64px;
  }

  @media (max-width: 556px) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 32px;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  color: var(--white);
  position: relative;
  z-index: 10;

  @media (max-width: 556px) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 12px;
  }
}
