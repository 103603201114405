.root {
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #040404;
}

.container {
  width: 81%;

  @media (min-width: 375px) {
    width: 82%;
  }
}
