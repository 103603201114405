.footerInfoContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  z-index: 1000;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media (min-width: 820px) {
  .footerInfoContainer {
    gap: 10px;
  }
}

.documentsList {
  display: flex;
  list-style-type: none;
  font-size: 12px;
  padding: 0;
  gap: 10px;
  flex-wrap: wrap;
}

.documentLink {
  color: white;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: #0366d6;
  }
}
