.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.navmenu {
  display: flex;
}

.box {
  border: 1px solid var(--white);
  width: 26px;
  height: 33px;
  margin-top: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 170px;
  z-index: 12;
  transition: all 0.5 ease-in-out;
  padding-top: 5px;
}

.label_container {
  width: 170px;
  display: flex;
  border-bottom: 1px solid var(--white);
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  transition: all 0.5 ease-in-out;

  &:hover {
    transition: all 0.5 ease-in-out;
    background-color: var(--white);

    .label {
      transition: all 0.5 ease-in-out;
      color: var(--black);
    }
  }
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: var(--white);
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 8px;
}

.container {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.login {
  padding: 7px 14px;
  border: 1px solid var(--white);
}

.main {
  width: 100vw;
  position: absolute;
}
