.substrate {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 20000;
  top: 50%;
  left: 50%;
}

.headerContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  color: var(--white);
}
.container {
  position: fixed;
  width: 680px;
  transform: translate(-50%, -50%);
  z-index: 20000;
  padding: 16px 16px;
  background-color: var(--black);
  border-radius: 8px;
  box-shadow: 8px 8px 30px 0px var(--black-3);
  max-height: 95vh;
  display: flex;
  gap: 24px;
  flex-direction: row;
  overflow-y: auto;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 350px;
    padding: 20px;
  }

  @media (max-width: 556px) {
    flex-direction: column;
    width: 350px;
    padding: 20px;
  }
}

.alert {
  opacity: 0;
  animation: ani 1s forwards;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.iconButton {
  left: 628px;
  top: 1px;
  position: fixed;
  z-index: 20001;
  @media (max-width: 768px) {
    bottom: 3px;
    left: 35px;
    position: relative;
    z-index: 20001;
  }

  @media (max-width: 556px) {
    bottom: 3px;
    position: relative;
    left: 35px;
    z-index: 20001;
  }
}
