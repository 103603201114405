.interactiveInfo {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.subTitle {
  font-family: var(--font-montserrat), sans-serif !important;
  font-size: clamp(
    14px,
    calc(14px + (16 - 14) * ((100vw - 820px) / (1440 - 820))),
    16px
  );
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 0.28px;
  word-wrap: break-word;
  color: var(--white);
}

.infoItemContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (min-width: 1440px) {
  .infoItemContainer {
    padding-top: 20px;
    flex-direction: row;
  }

  .interactiveInfo {
    gap: 36px;
  }
}
